/** @jsx jsx */
import React from 'react';
import { jsx, Box, Flex } from 'theme-ui';
import TextBlock from '../TextBlock';
import { ThemeHeader, TheRayLetterLogo, ImageFullWidth } from '../../components';
import CTALink from '../CTALink';
import useElementOnScreen from '../../hooks/useElementOnScreen';

const Banner = ({ pageBody }) => {
  const { _rawCta: cta, _rawText: text, _rawImage: bannerImage, _rawBroucherPdf: pdf, bgColor, title, styleType } = pageBody;

  const renderBanner = (type) => {
    switch (type) {
      case '1':
        return renderBannerStyleOne();

      case '2':
        return renderBannerStyleTwo();

      default:
        return renderBannerStyleOne();
    }
  };
  const getPdfData = (ctaData, pdfData) => {
    if (pdfData) {
      const pdfUrl = pdfData.asset.url;
      ctaData.link.linkUrl = pdfUrl;
    }
    return ctaData;
  };

  const [BannerImageRef, isBannerImageVisible] = useElementOnScreen();
  const [BannerLogoRef, isBannerLogoVisible] = useElementOnScreen();
  const [BannerHeadlineRef, isBannerHeadlineVisible] = useElementOnScreen();
  const [BannerContentRef, isBannerContentVisible] = useElementOnScreen();
  const [Banner1CTARef, isBanner1CTAVisible] = useElementOnScreen();
  const [Banner2CTARef, isBanner2CTAVisible] = useElementOnScreen();
  const [BannerVerticalLineRef, isBannerVerticalLineVisible] = useElementOnScreen();

  const renderBannerStyleOne = () => (
    <Box sx={BannerStyle1}>
      <Box sx={BannerStyle1.inner}>
        <Box ref={BannerImageRef} sx={{ transition: 'opacity 1s ease', opacity: isBannerImageVisible ? 1 : 0 }}>
          <ImageFullWidth style={BannerStyle1.img} key={Math.random().toString(36).substring(7)} src={bannerImage.asset._ref} alt="BannerImage" />
        </Box>
        {cta.link.showLink ? (
          <Box ref={Banner1CTARef} sx={{ ...BannerStyle1.CtaWrapp, ...{ transition: 'opacity 1s ease 1s', opacity: isBanner1CTAVisible ? 1 : 0 } }}>
            <CTALink linkData={getPdfData(cta, pdf)} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  const renderBannerStyleTwo = () => (
    <Box sx={BannerStyle2}>
      <Flex sx={BannerStyle2.inner}>
        {/* Refactor Required, Duplicate code, Can be use TextComponent */}
        <Box ref={BannerLogoRef} sx={{ ...BannerStyle2.logo, ...{ transition: 'opacity 1s ease', opacity: isBannerLogoVisible ? 1 : 0 } }}>
          <TheRayLetterLogo />
        </Box>
        <Box ref={BannerHeadlineRef} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isBannerHeadlineVisible ? 1 : 0 }}>
          <ThemeHeader.H1 isGradient isShimmer={isBannerHeadlineVisible}>
            {title}
          </ThemeHeader.H1>
        </Box>
        {text && (
          <Box ref={BannerContentRef} sx={{ transition: 'opacity 1s ease 0.5s', opacity: isBannerContentVisible ? 1 : 0 }}>
            <TextBlock description={text} />
          </Box>
        )}
        {cta.link.showLink ? (
          <Box ref={Banner2CTARef} sx={{ transition: 'opacity 1s ease 0.75s', opacity: isBanner2CTAVisible ? 1 : 0 }}>
            <CTALink linkData={getPdfData(cta, pdf)} />
          </Box>
        ) : null}
      </Flex>
      <Box ref={BannerVerticalLineRef} sx={BannerStyle2.verticalLine(isBannerVerticalLineVisible)}></Box>
    </Box>
  );

  return <Box style={{ backgroundColor: bgColor }}>{renderBanner(styleType)}</Box>;
};

// styles

const BannerStyle1 = {
  backgroundColor: 'mobileMenu',
  py: 4,
  mb: [5, 0],
  position: 'relative',
  zIndex: 1,
  '&:after, &:before': {
    content: '""',
    height: '100%',
    width: [8, null, 24],
    backgroundColor: 'background',
    position: 'absolute',
    top: 0,
    zIndex: -2,
  },
  '&:after': {
    left: 0,
  },
  '&:before': {
    right: 0,
  },
  inner: {
    position: 'relative',
    mt: 1,
    mb: [3, null, 1],
  },
  img: {
    height: [250, null, 725],
  },
  CtaWrapp: {
    backgroundColor: 'background',
    position: 'absolute',
    py: [1, null, 3],
    px: [3, null, 8],
    minWidth: [312, null, 440],
    textAlign: 'center',
    bottom: 0,
    right: 0,
  },
};

const BannerStyle2 = {
  position: 'relative',
  py: [5, null, 10],
  inner: {
    maxWidth: 640,
    mx: 'auto',
    position: 'relative',
    pb: 5,
    color: 'textInverted',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    mb: [4, null, 5],
  },
  verticalLine: (isBannerVerticalLineVisible) => ({
    variant: 'styles.goldLine',
    width: '1px',
    bottom: 0,
    left: 0,
    right: 0,
    mx: 'auto',
    position: 'absolute',
    transition: 'height 1s ease 1s',
    height: isBannerVerticalLineVisible ? [64, null, 125] : 0,
  }),
};

export default Banner;
